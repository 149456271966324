// import './style.scss'

const menuToggle = document.getElementById('menu-toggle');
const menu = document.querySelector('.menu');
const featuresTitle = document.getElementById('main-title');
const navbar = document.getElementById('nav');
let isNavHidden = true;

// if (window.scrollY < 400) {
//   console.log(`run first`, );
//   navbarLogo.classList.add('hide');
//   isNavHidden = true;
// }

const scrollCallback = () => {
  if (window.scrollY < 70 && !isNavHidden) {
    navbar.classList.add('hide-shadow');
    isNavHidden = true;
  } else if (window.scrollY > 70 && isNavHidden) {
    navbar.classList.remove('hide-shadow');
    isNavHidden = false;
  }
}

scrollCallback()

window.addEventListener('scroll', scrollCallback, {passive: true})

const activeFeatureCards = {
  planification: {
    el: document.querySelector('.timeline-card'),
    cardValue: 'timeline'
  },
  home: {
    el: document.querySelector('.birdview-card'),
    cardValue: 'birdview'
  },
  analytics: {
    el: document.querySelector('.analytic-card'),
    cardValue: 'history'
  },
}

let tick = false;

window.addEventListener('mousemove', (e) => {
  if (tick) return;
  tick = true;

  requestAnimationFrame(() => {
    if (!e.target.dataset.action) {
      tick = false;
      return;
    }

    if (e.target.dataset.action === 'feature-card') {
      if (activeFeatureCards[e.target.dataset.category].cardValue === e.target.dataset.value) {
        tick = false;
        return;
      };

      const previousActiveImage = document.getElementById(`${e.target.dataset.category}-${activeFeatureCards[e.target.dataset.category].cardValue}-image`);
      previousActiveImage.classList.add('hide');

      activeFeatureCards[e.target.dataset.category].el.classList.remove('active');

      activeFeatureCards[e.target.dataset.category].cardValue = e.target.dataset.value
      const newActiveImage = document.getElementById(`${e.target.dataset.category}-${activeFeatureCards[e.target.dataset.category].cardValue}-image`);
      newActiveImage.classList.remove('hide');

      e.target.classList.add('active');

      activeFeatureCards[e.target.dataset.category].el = e.target;
    }
    tick = false;
  });

}, {passive: true})



document.addEventListener('click', event => {
  const linkContainer = event.target.closest('.link-container');
  if (linkContainer) {
      const id = linkContainer.id;

      if (!['manifesto-button', 'features-button', 'three-steps-setup-button'].includes(id)) return;

      const elementToScrollIntoView = document.getElementById(id.replace('-button', ''));
      const boundingRect = elementToScrollIntoView.getBoundingClientRect();

      window.scrollBy({
        left: 0,
        top: boundingRect.top,
        // behavior: "smooth"
    });

    return;
  }
  // const signup = event.target.closest('.sign-up');
  // if (signup) {
  //   event.preventDefault();

  //   // // CHECK FORM VALID
  //   const form = event.target.closest('form');
  //   if (!form.checkValidity()) return;

  //   const honeypot = form.querySelector('.honeypot').value;
  //   if (honeypot) return;


  //   const data = [
  //     ['email' ,form.querySelector('.input-email').value],
  //     ['accessKey', form.querySelector('.accessKey').value],
  //     ['subject', form.querySelector('.subject').value]
  //   ]


  //   // encoded body request
  //   const body = data.map(field => {
  //     return `${field[0]}=${encodeURIComponent(field[1])}`;
  //   }).join('&');

  //   fetch('https://api.staticforms.xyz/submit', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //'application/json'
  //     },
  //     body
  //   })
  //   .then(response => response.json())
  //   .then(response => {
  //     if (response.success) {
  //       form.closest('.early-access-form').querySelector('.contact-success').style.display = 'block';
  //     }
  //   }).catch(error => {
  //     alert("Il semble y avoir un problème avec le formulaire, merci d'envoyer un email à support@farme.app");
  //   })
  //   return;
  // };

  // console.log('e.target.dataset.action: ', event.target.dataset.action);
   if (event.target.dataset.action === 'feature-card') {
      const previousActiveImage = document.getElementById(`${event.target.dataset.category}-${activeFeatureCards[event.target.dataset.category].cardValue}-image`);
      previousActiveImage.classList.add('hide');

      activeFeatureCards[event.target.dataset.category].el.classList.remove('active');

      activeFeatureCards[event.target.dataset.category].cardValue = event.target.dataset.value
      const newActiveImage = document.getElementById(`${event.target.dataset.category}-${activeFeatureCards[event.target.dataset.category].cardValue}-image`);
      newActiveImage.classList.remove('hide');

      event.target.classList.add('active');

      activeFeatureCards[event.target.dataset.category].el = event.target;
      return;
    }

  // Handle closing menu when clicked around
  if (event.target.dataset.action === 'toggle-menu') {
    event.preventDefault();

    if (menuToggle.checked) {
      const menu = event.target.closest('.menu');
      if (!menu) {
        menuToggle.checked = false;
        return;
      }
    }

    if (!menuToggle.checked) {
      const menu = event.target.closest('.menu-button-container');
      if (menu) {
        menuToggle.checked = true;
        return;
      }
    }
  }

  // event.preventDefault();
});


// menu.addEventListener('click', event => {
//   const linkContainer = event.target.closest('.link-container');
//   if (!linkContainer) return;


//   const id = linkContainer.id;

//   if (!['manifesto-button', 'features-button', 'three-steps-setup-button'].includes(id)) return;

//   const elementToScrollIntoView = document.getElementById(id.replace('-button', ''));
//   const boundingRect = elementToScrollIntoView.getBoundingClientRect();

//   window.scrollBy({
//     left: 0,
//     top: boundingRect.top,
//     behavior: "smooth"
//   });
// })